<template>
  <div class="pt-3">
    <Loader v-if="!backgroundChecks || (backgroundChecks && backgroundChecks.length == 0)" />
    <div class="dashboard__container--header" >
      <div class="text-left mb-3" style="width:100%;">
              
        <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': showOpen, 'btn__outlined': !showOpen }" @click="showOpenOrders()">Open</button>

        <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': showClosed, 'btn__outlined': !showClosed }" @click="showClosedOrders()">Closed</button>

        <button class="btn btn__small mr-3" v-bind:class="{ 'btn__dark': showCancelled, 'btn__outlined': !showCancelled }" @click="showCancelledOrders()">Cancelled</button>

      </div>
    </div>
    <div class="dashboard__container--body">
      <div class="flex" v-if="backgroundChecks && backgroundChecks.length >= 1" style="width:100%; overflow: auto;">
        <vue-good-table
          v-if="showOpen"
          @on-selected-rows-change="selectionChanged"
            :columns="columns"
            :rows="openChecks"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              searchFn: mySearchFn,
              placeholder: 'Search this table',
            }"
            :select-options="{
              enabled: true,
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              selectOnCheckboxOnly: true,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 25,
            }"
            :sort-options="{
              enabled: true,
              // initialSortBy: {field: 'userId', type: 'asc'}
            }"
          >
          <div slot="selected-row-actions">

            <button class="btn btn__small btn__dark mr-4" @click="exportReport()">
              Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'note'">
              <span>{{props.row.note}}</span>
            </span>

            <span v-else-if="props.column.field == 'status'">
              <v-select
                label="title" 
                :options="statuses"
                v-model="props.row.status"
                @input="updateStatus(props.row)"
                :clearable="false"
                >
              </v-select>
            </span>

            <span v-else-if="props.column.field == 'response'">
              <v-select
                label="title" 
                :options="responses"
                v-model="props.row.response"
                @input="updateStatus(props.row)"
                :clearable="true"
                >
              </v-select>
            </span>

            <span v-else-if="props.column.field == 'created'">
              <span>{{formatDate(props.row.created)}}</span>
            </span>

            <span v-else-if="props.column.field == 'actions'">
              <span class="flex pl-2">
                <button class="btn btn__icon mr-4" @click="text1(props.row)"><i class="fa-solid fa-paper-plane" :class="{ muted: props.row.text1, blueHue: !props.row.text1 }"></i></button>
                <button class="btn btn__icon mr-4" @click="text2(props.row)"><i class="fa-solid fa-paper-plane" :class="{ muted: props.row.text2, blueHue: !props.row.text2 }"></i></button>
                <button class="btn btn__icon" @click="text3(props.row)"><i class="fa-solid fa-paper-plane" :class="{ muted: props.row.text3, blueHue: !props.row.text3 }"></i></button>
              </span>
            </span>

            <span v-else-if="props.column.field == 'phoneNumber'">
                <span v-if="props.row.user && props.row.user.phone">
                  <a :href="'sms:' + props.row.user.phone" class="darkLink">{{props.row.user.phone}}</a>
                </span>
              </span>


            <span v-else-if="props.column.field == 'userDetails'">
              <span v-if="props.row.user && props.row.user.firstName && props.row.user.lastName">
                <router-link :to="'/users/' + props.row.user.id" class="darkLink">
                  {{props.row.user.firstName}} {{props.row.user.lastName}}
                </router-link>
              </span>
            </span>
            <span v-else-if="props.column.field == 'dob'">
              <span>{{props.row.user.dob | moment("M/D/YYYY") }}</span>
            </span>

            <span v-else-if="props.column.field == 'submittedBy'">
              {{props.row.orderedBy.firstName}} {{props.row.orderedBy.lastName}} 
            </span>
             <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span>
          </template>
        </vue-good-table>
        <vue-good-table
          v-if="showClosed"
          @on-selected-rows-change="selectionChanged"
            :columns="columns"
            :rows="closedChecks"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              searchFn: mySearchFn,
              placeholder: 'Search this table',
            }"
            :select-options="{
              enabled: true,
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              selectOnCheckboxOnly: true,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 25,
            }"
            :sort-options="{
              enabled: true,
              // initialSortBy: {field: 'userId', type: 'asc'}
            }"
          >
          <div slot="selected-row-actions">

            <button class="btn btn__small btn__dark mr-4" @click="exportReport()">
              Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'note'">
              <span>{{props.row.note}}</span>
            </span>

            <span v-else-if="props.column.field == 'status'">
              <v-select
                label="title" 
                :options="statuses"
                v-model="props.row.status"
                @input="updateStatus(props.row)"
                :clearable="false"
                >
              </v-select>
            </span>

            <span v-else-if="props.column.field == 'response'">
              <v-select
                label="title" 
                :options="responses"
                v-model="props.row.response"
                @input="updateResponse(props.row)"
                :clearable="true"
                >
              </v-select>
            </span>

            <span v-else-if="props.column.field == 'created'">
              <span>{{formatDate(props.row.created)}}</span>
            </span>
              <span v-else-if="props.column.field == 'phoneNumber'">
                <span v-if="props.row.user && props.row.user.phone">
                  <a :href="'sms:' + props.row.user.phone" class="darkLink">{{props.row.user.phone}}</a>
                </span>
              </span>


            <span v-else-if="props.column.field == 'userDetails'">
              <span v-if="props.row.user && props.row.user.firstName && props.row.user.lastName">
                <router-link :to="'/users/' + props.row.user.id" class="darkLink">
                  {{props.row.user.firstName}} {{props.row.user.lastName}}
                </router-link>
              </span>
            </span>
            <span v-else-if="props.column.field == 'dob'">
              <span>{{props.row.user.dob | moment("M/D/YYYY") }}</span>
            </span>

            <span v-else-if="props.column.field == 'submittedBy'">
              {{props.row.orderedBy.firstName}} {{props.row.orderedBy.lastName}} 
            </span>
             <!-- <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span> -->
          </template>
        </vue-good-table>
        <vue-good-table
          v-if="showCancelled"
          @on-selected-rows-change="selectionChanged"
            :columns="columns"
            :rows="cancelledChecks"
             styleClass="vgt-table condensed"
            :search-options="{
              enabled: true,
              searchFn: mySearchFn,
              placeholder: 'Search this table',
            }"
            :select-options="{
              enabled: true,
              selectionText: 'rows selected',
              clearSelectionText: 'clear',
              selectOnCheckboxOnly: true,
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 25,
            }"
            :sort-options="{
              enabled: true,
              // initialSortBy: {field: 'userId', type: 'asc'}
            }"
          >
          <div slot="selected-row-actions">

            <button class="btn btn__small btn__dark mr-4" @click="exportReport()">
              Export <i class="fa-regular fa-arrow-up-right-from-square ml-2"></i>
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest2">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
          <template slot="table-row" slot-scope="props">
            <span v-if="props.column.field == 'note'">
              <span>{{props.row.note}}</span>
            </span>

            <span v-else-if="props.column.field == 'status'">
              <v-select
                label="title" 
                :options="statuses"
                v-model="props.row.status"
                @input="updateStatus(props.row)"
                :clearable="false"
                >
              </v-select>
            </span>

            <span v-else-if="props.column.field == 'response'">
              <v-select
                label="title" 
                :options="responses"
                v-model="props.row.response"
                @input="updateResponse(props.row)"
                :clearable="true"
                >
              </v-select>
            </span>

            <span v-else-if="props.column.field == 'created'">
              <span>{{formatDate(props.row.created)}}</span>
            </span>
              <span v-else-if="props.column.field == 'phoneNumber'">
                <span v-if="props.row.user && props.row.user.phone">
                  <a :href="'sms:' + props.row.user.phone" class="darkLink">{{props.row.user.phone}}</a>
                </span>
              </span>


            <span v-else-if="props.column.field == 'userDetails'">
              <span v-if="props.row.user && props.row.user.firstName && props.row.user.lastName">
                <router-link :to="'/users/' + props.row.user.id" class="darkLink">
                  {{props.row.user.firstName}} {{props.row.user.lastName}}
                </router-link>
              </span>
            </span>
            <span v-else-if="props.column.field == 'dob'">
              <span>{{props.row.user.dob | moment("M/D/YYYY") }}</span>
            </span>

            <span v-else-if="props.column.field == 'submittedBy'">
              {{props.row.orderedBy.firstName}} {{props.row.orderedBy.lastName}} 
            </span>
             <!-- <span v-else>
              {{props.formattedRow[props.column.field]}}
            </span> -->
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>


<script>
import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
import ExportService from "@/services/ExportService"
const fb = require('../../firebaseConfig.js')

export default {
  name: 'backgroundChecks',
  data: () => ({
    performingRequest2: false,
    statuses: ['Requested', 'Submitted', 'Pending', 'Awaiting Results', 'Complete', 'Cancelled'],
    responses: ['Passed', 'Needs Review', 'Failed Do Not Hire', 'Duplicate'],
    batch: [],
    showOpen: true,
    showClosed: false,
    showCancelled: false,
    columns: [
      {
        label: 'Requested',
        field: 'created',
        width: '144px',
        sortable: false
      },
      {
        label: 'Type',
        field: 'type',
        width: '100px',
      },
      {
        label: 'Status',
        field: 'status',
        width: '180px',
      },
      {
        label: 'Send Texts',
        field: 'actions',
        width: '100px'
      },
      {
        label: 'User',
        field: 'userDetails',
        sortable: false,
        width: '160px',
      },
      {
        label: 'Phone',
        field: 'phoneNumber',
        width: '140px',
        sortable: false
      },
      {
        label: 'DOB',
        field: 'dob',
        width: '72px',
        sortable: false
      },
      {
        label: 'State',
        field: 'user.address.state',
        width: '60px',
        sortable: false
      },
      {
        label: 'Res/Conf',
        field: 'response',
        width: '200px'
      },
      {
        label: 'Submitted By',
        field: 'orderedBy',
        width: '100px',
        sortable: false,
        html: true
      },
      {
        label: 'Delete',
        field: 'delete',
        width: '72px',
        sortable: false
      },
    ]
  }),
  created () {
    // this.$store.dispatch("retrieveChecks")
    if (!this.backgroundChecks || this.backgroundChecks.length == 0) {
      this.$store.dispatch("getBackgroundChecks") 
    }
  },
  computed: {
    ...mapState(['userProfile', 'backgroundChecks']),

    sortedChecks() {
      return this.backgroundChecks.sort((a, b) => a.user.firstName.localeCompare(b.user.firstName))
        // return ((a.firstName && a.firstName.toLowerCase()) - (b.firstName && b.firstName.toLowerCase()));
      // })
    },
    openChecks() {
      return this.sortedChecks.filter(check => {
        return (check.status != 'Cancelled' && (check.status != 'Complete' || (check.status == 'Complete' && (!check.response || check.response == 'Needs Review'))))
      })
    },
    closedChecks() {
      return this.sortedChecks.filter(check => {
        return (check.status == 'Complete' && (check.response && check.response != 'Needs Review'))
      })
    },
    cancelledChecks() {
      return this.sortedChecks.filter(check => {
        return (check.status == 'Cancelled')
      })
    },
  },
  components: {
    Loader,
  },
  methods: {
    mySearchFn(row, col, cellValue, searchTerm) {
      if (searchTerm === "") return true;
      return row.user.firstName.toLowerCase().startsWith(searchTerm.toLowerCase()) || row.user.lastName.toLowerCase().startsWith(searchTerm.toLowerCase())
    }, 
    text1(row) {
      fb.backgroundChecksCollection.doc(row.id).update({
        text1: true
      })
    },
    text2(row) {
      fb.backgroundChecksCollection.doc(row.id).update({
        text2: true
      })
    },
    text3(row) {
      fb.backgroundChecksCollection.doc(row.id).update({
        text3: true
      })
    },
    showOpenOrders() {
      this.showOpen = true
      this.showClosed = false
      this.showCancelled = false
    },
    showClosedOrders() {
      this.showOpen = false
      this.showClosed = true
      this.showCancelled = false
    },
    showCancelledOrders() {
      this.showOpen = false
      this.showClosed = false
      this.showCancelled = true
    },
    selectionChanged(params) {
      this.batch = params.selectedRows
    },
    updateStatus(row) {
      console.log(row)
      fb.backgroundChecksCollection.doc(row.id).update(row)
    },
    updateResponse(row) {
      console.log(row)
      fb.backgroundChecksCollection.doc(row.id).update(row)
    },
    formatDate(q) {
      if(q) {
        const postedDate = new Date(q.seconds) * 1000;
        return moment(postedDate).format('MM/DD/YY, hh:mm A')
      } else {
        return null
      }
    },
    exportReport() {
      this.performingRequest2 = true
      const exportHeaders = [
        "First Name",
        "Last Name",
        "Status",
        "Phone",
        "DOB",
        "State",
        "Response",
      ];
      const exportItems = [];
      for (var key in this.batch) {

        exportItems.push([
          this.batch[key].user.firstName,
          this.batch[key].user.lastName,
          this.batch[key].status,
          this.batch[key].user.phone,
          this.batch[key].user.dob,
          this.batch[key].user.address.state || null,
          this.batch[key].response,
        ]);
      }
      this.$gapi.getGapiClient().then(gapi => {
        const exportService = new ExportService(exportHeaders, Object.values(exportItems), gapi);
        exportService.export();
      });
      setTimeout(() => {
        this.performingRequest2 = false
      }, 2000)
    },
  },
  beforeDestroy() {
    this.$store.dispatch("clearBackgroundChecks")
    this.batch = null
    delete this.batch
    this.showOpen = null
    delete this.showOpen
    this.performingRequest2 = null
    delete this.performingRequest2
    this.statuses = null
    delete this.statuses
    this.responses = null
    delete this.responses
    this.columns = null
    delete this.columns
  }
}
</script>